<template>
  <div class="slide-container" :ref="boxId + 'SlideContainer'" v-on:click.self="onBoxClick()">
    <div class="content" v-on:click.self="onBoxClick()">
      <div class="content-block" @click="onBoxClick()">
        <p class="headline">
          <slot name="headline"></slot>
        </p>
        <div class="slide-content-inner" v-if="isActive">
          <slot name="content"></slot>
        </div>
      </div>
      <swiper :ref="swiperId"
            :options="swiperOptions"
            @ready="onSwiperReady"
            v-if="isActive && slides">
        <swiper-slide v-for="(slide, index) in slides" :key="boxId + '-slide-' + index">
          <ba-icon-link :icon="slide.icon" :route="slide.route">
            {{ slide.text }}
          </ba-icon-link>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <div class="slide-content-inner content-block" @click="onBoxClick()" v-else>
        <b-row class="learn-more">
          <p class="box-read-more">
            <slot name="read-more"></slot>
          </p>
        </b-row>
        <div class="d-lg-none text-center">
          <font-awesome-icon icon="chevron-down" class="icon-slide-toggle"/>
        </div>
      </div>
      <div class="slide-action content-block" v-on:click.self="onBoxClick()">
        <slot name="action"></slot>
      </div>
    </div>
  </div>
</template>

<script>
  import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
  import BaIconLink from '@/components/partials/General/BaIconLink';
  import isMobileMixin from '@/mixins/isMobile';

  export default {
    mixins: [isMobileMixin],
    components: {
      Swiper,
      SwiperSlide,
      BaIconLink,
    },
    directives: {
      swiper: directive,
    },
    props: {
      boxId: {
        type: String,
        required: false,
        default: 'box-attachment-list',
      },
      isActive: {
        type: Boolean,
        default: false,
      },
      slides: {
        type: Array,
        required: false,
        default: function () { return []; },
      },
    },
    data () {
      return {
        swiperUserInteraction: false,
        swiperOptions: {
          direction: 'horizontal',
          loop: false,
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          slidesPerView: 'auto',
          watchOverflow: true,
          centeredSlides: true,
          preventClicks: true,
          preventClicksPropagation: true,
          slideToClickedSlide: true,
          // Some Swiper option/callback...
          // Responsive breakpoints
          breakpoints: {
            992: {
              freeMode: true,
              centeredSlides: true,
              centerInsufficientSlides: true,
            },
          },
        },
      };
    },
    computed: {
      swiperId () {
        return this.boxId + 'Swiper';
      },
      swiper () {
        const swiperComponent = this.$refs[this.swiperId];
        return swiperComponent ? swiperComponent.$swiper : null;
      },
    },
    methods: {
      onSwiperReady (swiper) {
        const self = this;
        if ((self.isViewport('lg') || self.isViewport('xl'))) {
          // Because the slide-container has a transition, the swiper-container changes its with after its initialized
          // => manually set the x-translation of the swiper wrapper to center the second slide
          self.centerActiveSlide(swiper, true);
        }
        swiper.on('touchStart', function () {
          self.swiperUserInteraction = true;
        });
        const windowOnResize = function () {
          if ((self.isViewport('lg') || self.isViewport('xl'))) {
            self.centerActiveSlide(swiper, false);
          }
        };
        window.addEventListener('resize', windowOnResize);
        swiper.on('beforeDestroy', function () {
          window.removeEventListener('resize', windowOnResize);
        });
      },
      centerActiveSlide (swiper, force) {
        const slideCount = swiper && swiper.slides ? swiper.slides.length : 0;
        if (slideCount > 1) {
          const centerActiveSlide = parseInt(Math.floor((swiper.slides.length - 1) / 2) + '');
          const containerElt = this.$refs[this.boxId + 'SlideContainer'];
          let isCenterSlideActive = parseInt(centerActiveSlide + '') === parseInt(swiper.realIndex);
          if (force && !isCenterSlideActive) {
            swiper.slideTo(centerActiveSlide);
            isCenterSlideActive = true;
          }
          if (containerElt && (force || isCenterSlideActive || !this.swiperUserInteraction)) {
            const lastSlide = swiper.slides[slideCount - 1];
            const totalSlideWidth = lastSlide.offsetLeft + lastSlide.offsetWidth;
            const containerTargetWidth = containerElt.parentElement.offsetWidth * 0.9;
            const translateTo = (containerTargetWidth / 2) - (totalSlideWidth / 2);
            swiper.translateTo(translateTo, 0);
          }
        }
      },
      onBoxClick () {
        this.$emit('toggle-box');
      },
    },
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";
  //@import "~swiper/swiper";
  @import "~swiper/scss/functions";

  $themeColor: $primary;
  $colors: ('white': #ffffff, 'black': #000000) !default;

  $leftColor: $primary;
  $rightColor: $ba-main-color4;
  $slide-bg-color-off: rgba(0, 0, 0, 0.3);
  $slide-bg-color-on: rgba(red($leftColor),green($leftColor),blue($leftColor), 0.8);

  @import "~swiper/components/core/core";
  @import "~swiper/components/pagination/pagination";
  .slide-container {
    cursor: pointer;
    height: 12.1875rem;
    width: 100%;
    position: relative;
    z-index: 5;
    transition: all .7s ease-in;

    &::before {
      content: "";
      background-color: rgba(red($leftColor),green($leftColor),blue($leftColor), 0.5);
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      opacity: 1;
      transition: background-color .2s linear;
      z-index: 1;
    }

    .content {
      position: relative;
      z-index: 5;
      width: 100%;
    }

    &.box-big,
    &:hover {
      &::before {
        background-color: rgba(red($leftColor),green($leftColor),blue($leftColor), 0.8);
      }
    }
    .box-read-more {
      display: flex;
      align-items: baseline;
      height: 60px;
      margin-bottom: 0;
      line-height: 20px;
      border-radius: $btn-border-radius;
      background: $ba-main-color1;
      color: #fff;
      transition: background-color .2s linear;
      opacity: 1;
      @include hover {
        background-color: $ba-main-color2;
      }
      svg {
        width: 20px;
        height: 20px;
        &:first-child {
          margin-right: 16px;
        }
        &:last-child {
          margin-left: 16px;
        }
      }
      @include media-breakpoint-up(lg) {
        display: inline-flex;
        line-height: 20px;
        border-radius: 30px;
        color: #fff;
        padding: 20px 48px;
        svg {
          display: none;
        }
      }

    }

    &.box-big,
    &:hover {
      &::before {
        background-color: $slide-bg-color-on;
      }
      .box-read-more {
        background-color: $ba-main-color2;
      }
    }
    .btn-slide-action {
      opacity: 0;
    }
    .slide-content-inner {
      margin-bottom: 0;
    }

    .btn {
      min-width: 261px;
    }

    .content-block {
      text-align: center;
      width: 100%;
      max-width: 630px;
      padding: 0 15px;
      margin: 0 auto;
      @include media-breakpoint-up(lg) {
        padding-left: 30px;
        text-align: left;
      }
      @include media-breakpoint-up(xl) {
        padding-left: 60px;
      }
    }

    .headline {
      z-index: 6;
      font-size: 1.5625rem;
      line-height: 1.304347826;
      text-align: center;
      margin-top: 1.25rem;
      margin-bottom: 0;
      font-weight: $font-weight-bold;
      letter-spacing: .12px;
      color: $ba-text-color-light;
      //text-transform: uppercase;
      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: auto;

      @include media-breakpoint-up(sm) {
        margin-top: 4rem;
      }

      @include media-breakpoint-up(lg) {
        margin-top: 0;
        font-size: 2.25rem;
        line-height: 1.2;
        text-align: left;
      }

      @include media-breakpoint-up(xl) {
        font-size: 2.875rem;
      }
    }

    .learn-more {
      display: none;
      font-size: 2rem;
      font-weight: $ba-font-weight-medium;
      line-height: 1.22;
      letter-spacing: 0.08125rem;
      margin: 1.5625rem 21%;
      @include media-breakpoint-up(lg) {
        display: block;
        font-size: 1.25rem;
        margin: 20px 0 0;
        text-align: left;
      }
    }

    .icon-slide-toggle {
      width: 20px;
      height: 20px;
    }

    .ba-icon-link {
      display: block;
      font-size: 19px;
      line-height: 26px;
      letter-spacing: .02px;
      text-align: center;
      padding: 10px 12px 0;
      text-decoration: none;
      position: relative;
      &::before {
        content: "";
        width: 40px;
        height: 40px;
        border: 2px solid $gray-100;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: 0;
        margin-left: -32px;
      }
      .icon {
        display: block;
        width: 20px;
        height: 20px;
        margin: 0 auto 15px;
      }
      span {
        display: block;
        width: 170px;
      }
      @include media-breakpoint-up(sm) {
        padding: 20px 25px 0;
        &::before {
          width: 80px;
          height: 80px;
          border: 4px solid $gray-100;
          margin-left: -40px;
        }
        .icon {
          width: 40px;
          height: 40px;
          margin: 0 auto 45px;
        }
      }
    }
    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      background: #fff;
      opacity: .66666;
      &.swiper-pagination-bullet-active {
        opacity: 1;
      }
    }
    .swiper-pagination {
      position: relative;
      margin: 20px 0 10px;
    }
    .swiper-slide {
      width: 220px;
    }
    .slide-action {
      margin-top: 10px;
    }

    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: flex-end;
      height: 560px;
      width: 50%;
      transition-duration: .4s;

      .btn-slide-action {
        transition: opacity .2s linear, color .2s linear;
      }
      .slide-content-inner {
        transition: margin-bottom .3s ease-in-out;
      }
      &.box-small {
        margin-left: -40%;
        transition-duration: 1s;
      }
      &.slide-out {
        .btn-slide-action {
          transition: opacity .2s linear, color .2s linear;
        }
      }
      /*&.right-container {*/
      /*  margin-left: auto;*/
      /*}*/
      .swiper-pagination {
        display: none;
      }
      .slide-action {
        margin-top: 0;
      }
    }

    @include media-breakpoint-up(xl) {
      height: 720px;
      width: 50%;

      .ba-icon-link {
        font-size: 22px;
        padding-top: 27px;
        &::before {
          border-width: 5px;
          width: 110px;
          height: 110px;
          border-radius: 55px;
          margin-left: -55px;
        }
        .icon {
          width: 55px;
          height: 55px;
          margin-bottom: 52px;
        }
        span {
          display: block;
          width: 220px;
        }
      }
      .swiper-slide {
        width: 270px;
      }
    }

    &.box-big {
      height: 40.25rem;
      .btn-slide-action {
        opacity: 1;
      }
      .slide-content-inner {
        margin-bottom: 40px;
      }
      @include media-breakpoint-up(lg) {
        align-items: center;
        // flex-grow: 3;
        width: 90%;
        height: auto;
        .content-block {
          max-width: 42rem;
          text-align: center;
          padding-left: 0;
        }
        .headline {
          text-align: center;
        }
        .slide-action {
          margin-top: 30px;
        }
        .btn-slide-action {
          transition: opacity .2s linear .2s, color .2s linear;
        }
        .slide-content-inner {
          margin-bottom: 20px;
        }
      }
      @include media-breakpoint-up(xl) {
        .slide-content-inner {
          margin-bottom: 40px;
        }
        .slide-action {
          margin-top: 50px;
        }
      }
    }
  }
</style>
