var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-card", { staticClass: "meta-card" }, [
    _c(
      "div",
      { staticClass: "icon" },
      [_c("font-awesome-icon", { attrs: { size: "2x", icon: _vm.icon } })],
      1
    ),
    _vm._v(" "),
    _c(
      "p",
      { staticClass: "text" },
      [
        _c("span", { staticClass: "value" }, [_vm._v(_vm._s(_vm.value))]),
        _vm._v(" "),
        _vm._t("default")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }