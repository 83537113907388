<template>
  <b-card class="meta-card">
    <div class="icon">
      <font-awesome-icon size="2x" :icon="icon"/>
    </div>
    <p class="text">
      <span class="value">{{ value }}</span>
      <slot/>
    </p>
  </b-card>
</template>

<script>
  export default {
    props: {
      icon: {
        type: [Array, String],
        default: '',
      },
      value: {
        type: [Number, String],
        required: false,
      },
      offset: {
        type: Number,
        required: false,
      },
      size: {
        type: Number,
        required: false,
      },
    },
  };
</script>

<style lang="scss">
  @import "../style/_init-vars-functions-mixins";
  .meta-card {
    &:not(:first-child) {
      margin-top: 2.25rem;
    }
    border: none;
    text-transform: uppercase;
    .icon {
      flex: 0 0 53.4px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      color: white;
      width: 53.4px;
      height: 53.4px;
      padding: 0;
      background: $primary;
      border-radius: 50%;
      @include media-breakpoint-up(xl) {
        margin: 0 16px 0 0;
      }
      svg {
        max-width: 28px;
        max-height: 26px;
      }
    }
    .value {
      color: $secondary;
    }
    .text {
      font-size: 1.25rem;
      font-weight: $font-weight-normal;
      line-height: 1.1875;
      text-align: center;
      margin-top: 0.9rem;
      margin-bottom: 0;
      @include media-breakpoint-up(xl) {
        font-size: 2rem;
        text-align: left;
        margin-top: 0;
      }
    }
    @include media-breakpoint-up(xl) {
      margin-top: 0;
      .card-body {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .text {
        color: $ba-light-primary;
        margin-top: 0;
        letter-spacing: 3.2px;
        .value {
          color: $secondary;
        }
      }
      // Weincampus MOD BEGIN
      .icon {
        display: none;
      }
      .text {
        color: $ba-main-color2;
        font-size: $ba-h2-font-size-lg;
        margin-top: 0;
        letter-spacing: 0;
        .value {
          color: $ba-light-primary;
          display: block;
          font-size: $ba-h1-font-size-lg;
          font-weight: $font-weight-bold;
          margin-bottom: 12px;
        }
      }
      // Weincampus MOD END
    }
  }
  .row-meta-cards {
    background: $ba-default-background;
    padding-top: 20px;
    padding-bottom: 20px;
    text-transform: uppercase;
    order: 3;
    //padding-top: 36px;
    //margin-top: 36px;
    margin-bottom: 45px;
    .card {
      background: transparent;
    }

    @include media-breakpoint-up(lg) {
      .meta-card {
        padding: 10px 56px;
        &.wide:last-child {
          //padding-left: 50px;
          //padding-right: 0;
        }
      }
    }

    @include media-breakpoint-up(xl) {
      > .container {
        display: flex;
      }
      .meta-card {
        flex: 0 0 50%;
        padding: 10px 26px;
        position: relative;
        &.wide {
          flex: 1 1 auto;
        }
        //&:not(:last-child)::after {
        //  content: "";
        //  display: block;
        //  position: absolute;
        //  right: -1px;
        //  top: 0;
        //  width: 2px;
        //  height: 100%;
        //  background: $ba-default-background;
        //}
      }
    }
  }
</style>
