var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "full-width order-0 order-lg-1",
      class: _vm.outerBoxClass,
      attrs: { id: "account-type-container" }
    },
    [
      _c(
        "transition",
        { attrs: { name: "slide-fade" } },
        [
          _c("ba-slide", {
            staticClass: "left-container",
            class: _vm.leftBoxClass,
            attrs: {
              "box-id": "applicant",
              "is-active": _vm.activeBox === "left",
              slides: _vm.leftSlides
            },
            on: {
              "toggle-box": function($event) {
                return _vm.onBoxClick("left")
              }
            },
            scopedSlots: _vm._u([
              {
                key: "headline",
                fn: function() {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t(
                            "components.partials.home.ba_intro_slider.left.headline_1"
                          )
                        )
                    ),
                    _c("br"),
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t(
                            "components.partials.home.ba_intro_slider.left.headline_2"
                          )
                        ) +
                        "\n      "
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "content",
                fn: function() {
                  return [
                    _c("p", [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "components.partials.home.ba_intro_slider.left.teaser"
                            )
                          )
                        )
                      ])
                    ])
                  ]
                },
                proxy: true
              },
              {
                key: "read-more",
                fn: function() {
                  return [
                    _c("font-awesome-icon", {
                      staticClass: "icon",
                      attrs: { icon: "arrow-left" }
                    }),
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t(
                            "components.partials.home.ba_intro_slider.more"
                          )
                        ) +
                        "\n      "
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "action",
                fn: function() {
                  return [
                    _c(
                      "b-btn",
                      {
                        staticClass: "btn-slide-action",
                        attrs: { variant: "light" },
                        on: {
                          click: function($event) {
                            return _vm.$router.push({ name: "companysearch" })
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("Suche Ausbildungsbetrieb")))]
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("ba-slide", {
        staticClass: "right-container",
        class: _vm.companyBoxClass,
        attrs: {
          "box-id": "company",
          "is-active": _vm.activeBox === "company",
          slides: _vm.rightSlides
        },
        on: {
          "toggle-box": function($event) {
            return _vm.onBoxClick("company")
          }
        },
        scopedSlots: _vm._u([
          {
            key: "headline",
            fn: function() {
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "components.partials.home.ba_intro_slider.right.headline_1"
                      )
                    )
                  )
                ]),
                _c("br"),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "components.partials.home.ba_intro_slider.right.headline_2"
                      )
                    )
                  )
                ])
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c("p", [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "components.partials.home.ba_intro_slider.right.teaser"
                        )
                      )
                    )
                  ])
                ])
              ]
            },
            proxy: true
          },
          {
            key: "read-more",
            fn: function() {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$t("components.partials.home.ba_intro_slider.more")
                    ) +
                    "\n      "
                ),
                _c("font-awesome-icon", {
                  staticClass: "icon",
                  attrs: { icon: "arrow-right" }
                })
              ]
            },
            proxy: true
          },
          {
            key: "action",
            fn: function() {
              return [
                _c(
                  "b-btn",
                  {
                    staticClass: "btn-slide-action",
                    attrs: { variant: "light" },
                    on: {
                      click: function($event) {
                        return _vm.$router.push({
                          name: "register",
                          params: { type: "ausbildungsbetrieb" }
                        })
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("Jetzt registrieren")))]
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }