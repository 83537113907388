var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: _vm.boxId + "SlideContainer",
      staticClass: "slide-container",
      on: {
        click: function($event) {
          if ($event.target !== $event.currentTarget) {
            return null
          }
          return _vm.onBoxClick()
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "content",
          on: {
            click: function($event) {
              if ($event.target !== $event.currentTarget) {
                return null
              }
              return _vm.onBoxClick()
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "content-block",
              on: {
                click: function($event) {
                  return _vm.onBoxClick()
                }
              }
            },
            [
              _c("p", { staticClass: "headline" }, [_vm._t("headline")], 2),
              _vm._v(" "),
              _vm.isActive
                ? _c(
                    "div",
                    { staticClass: "slide-content-inner" },
                    [_vm._t("content")],
                    2
                  )
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _vm.isActive && _vm.slides
            ? _c(
                "swiper",
                {
                  ref: _vm.swiperId,
                  attrs: { options: _vm.swiperOptions },
                  on: { ready: _vm.onSwiperReady }
                },
                [
                  _vm._l(_vm.slides, function(slide, index) {
                    return _c(
                      "swiper-slide",
                      { key: _vm.boxId + "-slide-" + index },
                      [
                        _c(
                          "ba-icon-link",
                          { attrs: { icon: slide.icon, route: slide.route } },
                          [
                            _vm._v(
                              "\n          " + _vm._s(slide.text) + "\n        "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "swiper-pagination",
                    attrs: { slot: "pagination" },
                    slot: "pagination"
                  })
                ],
                2
              )
            : _c(
                "div",
                {
                  staticClass: "slide-content-inner content-block",
                  on: {
                    click: function($event) {
                      return _vm.onBoxClick()
                    }
                  }
                },
                [
                  _c("b-row", { staticClass: "learn-more" }, [
                    _c(
                      "p",
                      { staticClass: "box-read-more" },
                      [_vm._t("read-more")],
                      2
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-lg-none text-center" },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "icon-slide-toggle",
                        attrs: { icon: "chevron-down" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "slide-action content-block",
              on: {
                click: function($event) {
                  if ($event.target !== $event.currentTarget) {
                    return null
                  }
                  return _vm.onBoxClick()
                }
              }
            },
            [_vm._t("action")],
            2
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }