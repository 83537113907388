<template>
  <div>
    <div class="order-2 order-lg-0">
      <ba-module-header>
        <template v-slot:title>
          {{ $t('views.home.header_title') }}
        </template>
        <template v-slot:caption>
          {{ $t('views.home.header_caption') }}
        </template>
        <template v-slot:lead>
          {{ $t('views.home.header_lead') }}
        </template>
      </ba-module-header>
    </div>
    <ba-intro-slider/>
    <!--
    <div class="row-meta-cards">
    <b-container class="home-info-box">
      <ba-meta-card :value="statistic(RECORD_TYPE.COMPANY)" v-if="statistic(RECORD_TYPE.COMPANY)" :icon="['far', 'building']">
        <span>{{ $t('views.home.statistics_companies') }}</span>
      </ba-meta-card>
      <ba-meta-card :value="statistic(RECORD_TYPE.APPLICANT)" v-if="statistic(RECORD_TYPE.APPLICANT)" icon="user">
        <span>{{ $t('views.home.statistics_applicants') }}</span>
      </ba-meta-card>
      <ba-meta-card :value="statistic('mathx3')" v-if="statistic('mathx3')" icon="chart-bar">
        <span>{{ $t('views.home.statistics_tests') }}</span>
      </ba-meta-card>
    </b-container>
    </div>
    -->
    <div class="row-content content-box home-content-box">
      <b-container>
      <h1>Das perfekte Match –<br>Wie funktioniert die Ausbildungsbörse?</h1>
      <!--<p class="teaser">Im dualen Studium braucht man nicht nur die richtige Hochschule, sondern auch den richtigen Ausbildungsbetrieb, um erfolgreich zu sein. Deswegen haben wir am Weincampus Neustadt Dual Match ins Leben gerufen. Hier sollen Studieninteressierte und Winzerbetriebe sich gegenseitig suchen und finden.</p>-->
      <p>Die Ausbildungsbörse hat zum Ziel, einfach, schnell und sicher die richtigen Winzerbetriebe mit den passenden Studienbewerbern zusammenzubringen. Studieninteressierte können darin ihr Bewerberprofil hinterlegen und präsentieren dort ihre Fähigkeiten, Vorkenntnisse und Interessen. Die angemeldeten Ausbildungspartner können dann gezielt aus dem Pool an Bewerbern nach geeigneten Kandidat*innen suchen und diese ganz bequem über das Portal kontaktieren. Über den dauerhaft hinterlegten Suchfilter werden Ausbildungsunternehmen regelmäßig über neue passende Bewerber informiert.</p>
      <!--<p class="highlight">Bei Fragen, Problemen und Anmerkungen stehen wir Ihnen unter der<br>
        Service-Mailadresse: <a href="mailto:dualmatch@hwg-lu.de">dualmatch@hwg-lu.de</a> zur Verfügung.</p>-->
    </b-container>
    </div>
  </div>
</template>

<script>
  import BaModuleHeader from '@/components/partials/General/BaModuleHeader';
  import BaIntroSlider from '@/components/partials/Home/BaIntroSlider';
  import BaMetaCard from '@/components/BaMetaCard';

  import { getUsageStatistics } from '@/api/statistics';
  import { RECORD_TYPE } from '@/api/constants';

  export default {
    metaInfo () {
      return {
        title: this.$t('views.home.meta.title'),
        meta: [
          { vmid: 'description', name: 'description', content: this.$t('views.home.meta.description') },
          { vmid: 'og:title', property: 'og:title', content: this.$t('views.home.meta.title') },
        ],
      };
    },
    components: {
      BaModuleHeader,
      BaIntroSlider,
      BaMetaCard,
    },
    data: () => ({
      RECORD_TYPE: RECORD_TYPE,
      statistics: {},
    }),
    methods: {
      statistic: function (type) {
        // TODO: use global constant in statistics api return value (so type can be used directly)
        let statType = type;
        if (type === RECORD_TYPE.APPLICANT) {
          statType = 'applicants';
        } else if (type === RECORD_TYPE.COMPANY) {
          statType = 'companies';
        }
        let value = this.statistics[statType];
        if (value) {
          if (value > 1000) {
            value = (Math.round(value / 100) / 10) + 'K';
          }
          return value;
        }
        return null;
      },
    },
    beforeMount: function () {
      getUsageStatistics()
        .then(({ data: statistics }) => {
          this.statistics = statistics;
        })
      ;
    },
  };
</script>

<style lang="scss">
  @import "../style/_init-vars-functions-mixins";
  .home-info-box {
    //&::before {
    //  content: "";
    //  display: block;
    //  border-top: $ba-home-info-box-border-top;
    //}
    //@include media-breakpoint-up(lg) {
    //  &::before {
    //    display: none;
    //  }
    //}
    @include media-breakpoint-up(xl) {
      padding-left: 0;
      padding-right: 0;
      .meta-card {
        flex: 1 1 auto;
        padding: 0;
        max-width: $ba-home-info-box-meta-card-xl-max-width;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
  .home-content-box {
    order: 4;
  }
</style>

<i18n lang="yaml" locale="de">
  views.home.meta.title: 'Dual Match – Die Ausbildungsbörse am Weincampus Neustadt'
  views.home.meta.description: 'Studieninteressierte und Ausbildungsbetriebe können sich bei Dual Match gegenseitig suchen und finden. Für das perfekte Match im dualen Studium – jetzt registrieren.'
  views.home.header_title: 'Dual Match'
  views.home.header_caption: 'Die Ausbildungsbörse für das duale Studium am Weincampus Neustadt'
  views.home.header_lead: 'Im dualen Studium braucht man nicht nur die richtige Hochschule, sondern auch den richtigen Ausbildungsbetrieb, um erfolgreich zu sein. Deswegen haben wir am Weincampus Neustadt Dual Match ins Leben gerufen. Hier sollen Studieninteressierte und Winzerbetriebe sich gegenseitig suchen und finden.'
  views.home.statistics_applicants: 'Bewerber* innen'
  views.home.statistics_companies: 'Firmen- Accounts'
  views.home.statistics_tests: 'absolvierte MathX³-Tests'
</i18n>
