<template>
  <section id="account-type-container" class="full-width order-0 order-lg-1" :class="outerBoxClass">
    <transition name="slide-fade">
      <ba-slide class="left-container" box-id="applicant" :class="leftBoxClass"
          :is-active="activeBox === 'left'"
          :slides="leftSlides"
          @toggle-box="onBoxClick('left')"
      >
        <template v-slot:headline>
          {{ $t('components.partials.home.ba_intro_slider.left.headline_1') }}<br>
          {{ $t('components.partials.home.ba_intro_slider.left.headline_2') }}
        </template>
        <template v-slot:content>
          <p><span>{{ $t('components.partials.home.ba_intro_slider.left.teaser') }}</span></p>
        </template>
        <template v-slot:read-more>
          <font-awesome-icon class="icon" icon="arrow-left" />
          {{ $t('components.partials.home.ba_intro_slider.more') }}
        </template>
        <template v-slot:action>
          <b-btn variant="light" class="btn-slide-action" @click="$router.push({ name: 'companysearch' })">{{ $t('Suche Ausbildungsbetrieb') }}</b-btn>
        </template>
      </ba-slide>
    </transition>
    <ba-slide class="right-container" box-id="company" :class="companyBoxClass"
              :is-active="activeBox === 'company'"
              :slides="rightSlides"
              @toggle-box="onBoxClick('company')"
    >
      <template v-slot:headline>
        <span>{{ $t('components.partials.home.ba_intro_slider.right.headline_1') }}</span><br>
        <span>{{ $t('components.partials.home.ba_intro_slider.right.headline_2') }}</span>
      </template>
      <template v-slot:content>
        <p><span>{{ $t('components.partials.home.ba_intro_slider.right.teaser') }}</span></p>
      </template>
      <template v-slot:read-more>
        {{ $t('components.partials.home.ba_intro_slider.more') }}
        <font-awesome-icon class="icon" icon="arrow-right" />
      </template>
      <template v-slot:action>
        <b-btn variant="light" class="btn-slide-action" @click="$router.push({ name: 'register', params: { type: 'ausbildungsbetrieb' } })">{{ $t('Jetzt registrieren') }}</b-btn>
      </template>
    </ba-slide>
  </section>
</template>

<script>
  import BaSlide from '@/components/partials/Home/BaSlide';

  export default {
    components: {
      BaSlide,
    },
    data: () => ({
      activeBox: '',
      lastActiveBox: '',
      // TODO @BrainAppeal: get slides by api or config
      leftSlides: [
        // {
        //   text: 'Lass dich mit deinem Profil von dualen Partnerbetrieben finden',
        //   icon: 'id-card',
        //   route: {
        //     name: 'register',
        //     params: {
        //       type: 'applicant',
        //     },
        //   },
        // },
        // {
        //   text: 'Finde freie Plätze und duale Partner für deine Bewerbung',
        //   icon: 'building',
        //   route: {
        //     name: 'companysearch',
        //     params: {
        //     },
        //   },
        // },
        // {
        //   text: 'Finde alle Infos zur Studienvorbereitung und Beratung',
        //   icon: 'tasks',
        //   route: {
        //     name: 'studypreparation',
        //   },
        // },
      ],
      rightSlides: [
        {
          text: 'Finden Sie passende Auszubildende',
          icon: 'search',
          route: {
            name: 'register',
            params: {
              type: 'ausbildungsbetrieb'
            },
          },
        },
        // {
        //   text: 'Nutzen Sie Dual Match für Ihr Recruiting-Management',
        //   icon: 'user-cog',
        //   route: {
        //     name: 'register',
        //     params: {
        //       type: 'ausbildungsbetrieb'
        //     },
        //   },
        // },
        {
          text: 'Informieren Sie sich über weitere duale Partner',
          icon: 'building',
          route: {
            name: 'companysearch',
            params: {
            },
          },
        },
      ],
    }),
    methods: {
      onBoxClick (box = '') {
        if (this.activeBox === box) {
          // close on double click
          this.activeBox = '';
        } else {
          // set clicked box
          this.activeBox = box;
          this.lastActiveBox = box;
        }
      },
    },
    computed: {
      outerBoxClass () {
        return this.lastActiveBox === 'left' ? 'box-active-left' : this.lastActiveBox === 'company' ? 'box-active-right' : '';
      },
      leftBoxClass () {
        return this.activeBox === 'left' ? 'box-big' : this.activeBox === 'company' ? 'box-small' : this.lastActiveBox === 'left' ? 'slide-out' : '';
      },
      companyBoxClass () {
        return this.activeBox === 'company' ? 'box-big' : this.activeBox === 'left' ? 'box-small' : this.lastActiveBox === 'company' ? 'slide-out' : '';
      },
    },
  };
</script>

<style lang="scss">
  @import "../../../style/_init-vars-functions-mixins";

  $leftColor: $primary;
  $rightColor: $ba-main-color4;

  #account-type-container {
    display: flex;
    flex-direction: column;
    color: $ba-text-color-light;
    /*margin-left: calc(50% - 50vw);*/
    /*margin-right: calc(50% - 50vw);*/
    margin-bottom: 21px;
    position: relative;
    width: 100%;

    #carousel-fade {
      text-shadow: 0 0 2px #000;
    }

    a,
    a:hover,
    a:active,
    a:focus,
    a:visited {
      color: $ba-text-color-light;
    }

    .left-container {
      background: url("/img/slider_bewerber.jpg") no-repeat center center;
      background-size: cover;
    }

    .right-container {
      z-index: 9;
      background: url("/img/slider_unternehmen.jpg") no-repeat center center;
      background-size: cover;
      transition: all 1s ease-out;
      &::before {
        background-color: rgba(red($rightColor),green($rightColor),blue($rightColor), 0.3);
      }
      &.box-big,
      &:hover {
        &::before {
          background-color: rgba(red($rightColor),green($rightColor),blue($rightColor), 0.8);
        }
      }

      @include media-breakpoint-up(lg) {
        &.box-small {
          margin-left: 0;
          margin-right: -40%;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      flex-wrap: nowrap;
      overflow: hidden;
      margin-top: 29px;
      margin-bottom: 0;
      .slide-container {
        //flex: 0 0 50%;
        min-width: 50%;
      }
      &.box-active-right {
        position: relative;
        &::before {
          content: "";
          z-index: 0;
          background-color: rgba(red($rightColor),green($rightColor),blue($rightColor), 0.8);
          //background-color: rgba(46, 65, 89, 0.5);
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
        }
      }
      &.box-active-left {
        position: relative;
        &::before {
          content: "";
          z-index: 0;
          background-color: rgba(red($leftColor),green($leftColor),blue($leftColor), 0.5);
          //background-color: rgba(161, 0, 0, 0.5);
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
        }
      }
    }
  }
</style>

<i18n lang="yaml" locale="de">
  components.partials.home.ba_intro_slider.left.headline_1: 'Ich suche einen'
  components.partials.home.ba_intro_slider.left.headline_2: 'Ausbildungsbetrieb'
  components.partials.home.ba_intro_slider.left.teaser: 'Dual Match ist die Bewerberplattform vom Weincampus Neustadt. Sie bringt dich einfach, schnell und sicher mit passenden Unternehmen zusammen und unterstützt dich bei der Studienvorbereitung.'
  components.partials.home.ba_intro_slider.right.headline_1: 'Ich suche nach'
  components.partials.home.ba_intro_slider.right.headline_2: 'Auszubildenden'
  components.partials.home.ba_intro_slider.right.teaser: 'Dual Match ist die Bewerberplattform vom Weincampus Neustadt. Sie bringt Sie einfach, schnell und sicher mit passenden Studieninteressierten zusammen.'
  components.partials.home.ba_intro_slider.more: 'Mehr erfahren'
</i18n>
