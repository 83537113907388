var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "order-2 order-lg-0" },
        [
          _c("ba-module-header", {
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function() {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("views.home.header_title")) +
                        "\n      "
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "caption",
                fn: function() {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("views.home.header_caption")) +
                        "\n      "
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "lead",
                fn: function() {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("views.home.header_lead")) +
                        "\n      "
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("ba-intro-slider"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row-content content-box home-content-box" },
        [
          _c("b-container", [
            _c("h1", [
              _vm._v("Das perfekte Match –"),
              _c("br"),
              _vm._v("Wie funktioniert die Ausbildungsbörse?")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Die Ausbildungsbörse hat zum Ziel, einfach, schnell und sicher die richtigen Winzerbetriebe mit den passenden Studienbewerbern zusammenzubringen. Studieninteressierte können darin ihr Bewerberprofil hinterlegen und präsentieren dort ihre Fähigkeiten, Vorkenntnisse und Interessen. Die angemeldeten Ausbildungspartner können dann gezielt aus dem Pool an Bewerbern nach geeigneten Kandidat*innen suchen und diese ganz bequem über das Portal kontaktieren. Über den dauerhaft hinterlegten Suchfilter werden Ausbildungsunternehmen regelmäßig über neue passende Bewerber informiert."
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }